<template>
  <div
    class="header animate__animated animate__slideInDown"
    style="animation-duration: 500ms"
  >
    <div class="header-left">
      <el-image
        class="header-left__logo"
        :class="{ 'header-left__logo--zl': appPlatform === 'zl' }"
        :src="`/${appBackendContextPath}/template/logo1.gif`"
      >
        <span slot="error"></span>
      </el-image>
      <div class="header-left__title">
        <div class="header-left__titleZh">
          {{ appSysConfig.systemName.value }}
        </div>
        <div class="header-left__titleEn">
          {{ appSysConfig.systemNameEn.value }}
        </div>
      </div>
    </div>
    <div class="header-right">
      <el-popover placement="bottom" trigger="hover">
        <div slot="reference" class="header-right__explain">
          <i class="icon iconfont ic_info-circle"></i>
          <span>系统说明</span>
        </div>
        <div class="header-right__explain-info" v-if="appPlatform === 'bt' && false">
          <div class="header-right__explain-info__title">尊敬的用户:</div>
          <div class="header-right__explain-info__content">
            如果您在使用该系统过程中遇到问题,可通过以下方式联系管理员：
          </div>
          <div class="header-right__explain-info__item">
            电话: <a>18610232845</a>
          </div>
          <div class="header-right__explain-info__item">
            邮箱: <a href="mailto:cao.zheng@bj-big.com">cao.zheng@bj-big.com</a>
          </div>
          <div class="header-right__explain-info__item">
            点击
            <a
              :href="`/${appBackendContextPath}/template/manual.pdf`"
              target="_blank"
              >用户使用手册
            </a>
            了解更多
          </div>
        </div>
        <div class="header-right__explain-info" v-if="appPlatform === 'zl' && false">
          <div class="header-right__explain-info__title">尊敬的用户:</div>
          <div class="header-right__explain-info__content">
            如果您在使用该系统过程中遇到问题,可通过以下方式联系管理员：
          </div>
          <div class="header-right__explain-info__item">
            手机号: <a>13370156316</a>
          </div>
          <div class="header-right__explain-info__item">
            邮箱: <a href="mailto:zhaijing@cofco.com">zhaijing@cofco.com</a>
          </div>
          <!-- <div class="header-right__explain-info__item">
            点击
            <a
              :href="`/${appBackendContextPath}/template/manual.pdf`"
              target="_blank"
              >用户使用手册
            </a>
            了解更多
          </div> -->
        </div>
        <div class="header-right__explain-info">
          <div class="header-right__explain-info__title">尊敬的用户:</div>
          <div class="header-right__explain-info__content">
            如果您在使用本系统过程中遇到问题,可通过以下方式联系管理员：
          </div>
           <div class="header-right__explain-info__item">
           联系人: <a>{{this.adminInfo.realName}}</a>
          </div>
          <div class="header-right__explain-info__item">
           手机号码: <a>{{this.adminInfo.phone}}</a>
          </div>
          <div class="header-right__explain-info__item">
            电子邮箱: <a href="mailto:zhaijing@cofco.com">{{this.adminInfo.email}}</a>
          </div>
          <!-- <div class="header-right__explain-info__item">
            点击
            <a
              :href="`/${appBackendContextPath}/template/manual.pdf`"
              target="_blank"
              >用户使用手册
            </a>
            了解更多
          </div> -->
        </div>
      </el-popover>

      <el-popover placement="bottom" trigger="hover">
        <div class="header-right__userInfo" slot="reference">
          <div
            v-show="userUserInfo.umsUser.department"
            class="ellipsis header-right__organization"
          >
            <i class="icon iconfont ic_bank"></i>
            {{ userUserInfo.umsUser.department }}
          </div>
          <i class="icon iconfont ic_yonghu"></i>
          <div class="header-right__userInfo-realName">
            {{ userUserInfo.umsUser.realName }}
          </div>
        </div>
        <div class="header-right__userInfo-popover">
          <div class="header-right__userInfo-popover__avatar">
            <i class="icon iconfont ic_user_fill"></i>
          </div>
          <div class="header-right__userInfo-popover__realName">
            {{ userUserInfo.umsUser.realName }}
          </div>
          <div class="header-right__userInfo-popover__line"></div>
          <div class="header-right__userInfo-popover__item">
            <span class="header-right__userInfo-popover__item-title">角色</span>
            <span class="header-right__userInfo-popover__item-content">{{
              userUserInfo.umsUser.roleType | umsUserRoleType
            }}</span>
          </div>
          <div class="header-right__userInfo-popover__item" v-show="loginType==='username'">
            <span class="header-right__userInfo-popover__item-title">用户名</span>
            <span class="header-right__userInfo-popover__item-content">{{
              userUserInfo.umsUser.username
            }}</span>
          </div>
          <div class="header-right__userInfo-popover__item" v-show="loginType==='phone'">
            <span class="header-right__userInfo-popover__item-title">手机号</span>
            <span class="header-right__userInfo-popover__item-content">{{
                userUserInfo.umsUser.phone
              }}</span>
          </div>
          <div class="header-right__userInfo-popover__item" v-show="loginType==='email'">
            <span class="header-right__userInfo-popover__item-title">邮箱</span>
            <span class="header-right__userInfo-popover__item-content">{{
                userUserInfo.umsUser.email
              }}</span>
          </div>
          <div class="header-right__userInfo-popover__item">
            <span class="header-right__userInfo-popover__item-title">单位</span>
            <span class="header-right__userInfo-popover__item-content">{{
              userUserInfo.umsUser.department
            }}</span>
          </div>
          <!-- <div class="header-right__userInfo-popover__username">
            {{ userUserInfo.umsUser.username }}
          </div>
          <div class="header-right__userInfo-popover__organization">
            {{ userUserInfo.umsUser.organization }}
          </div> -->
          <ReportButton
          v-show="appPlatform!='gx'"
            type="primary"
            plain
            style="
              display: block;
              margin: 0 auto;
              width: 256px;
              height: 40px;
              margin-top: 24px;
              margin-bottom: 16px;
            "
            @click="open"
            >修改密码</ReportButton
          >
        </div>
      </el-popover>
      <div @click="handlerLogout" class="header-right__logout-btn">
        <i class="icon iconfont ic_login_out"></i>退出
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { userLogout } from '@/api/user'
import ReportButton from '@/components/report-button'
import { dictionarySelectDictionaryListByKey } from '@/api/dictionary'
export default {
  data() {
    return {
      loginType: '',
      adminInfo: {}
    }
  },
  name: 'HeaderComponents',
  components: {
    ReportButton
  },
  computed: {
    ...mapGetters({
      appBackendContextPath: 'app/backendContextPath',
      appSysConfig: 'app/sysConfig',
      appPlatform: 'app/platform',
      userUserInfo: 'user/userInfo'
    })
  },
  created() {
    this.loginType = localStorage.getItem('loginType')
    this.dictionarySelectDictionaryListByKeyService({ key: 'adminInfo' })
  },
  methods: {
    async dictionarySelectDictionaryListByKeyService(params) {
      try {
        const data = await dictionarySelectDictionaryListByKey(params)
        if (data.code === 200) {
          if (data.data && data.data[0]) {
            this.adminInfo = JSON.parse(data.data[0].value)
          }
        } else {
          this.$message.error(data.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    open() {
      this.$emit('handleChange', true)
    },
    handlerLogout() {
      const id = localStorage.getItem('loginLogId')
      if (id !== null && typeof id !== 'undefined' && id !== '') {
        userLogout({ loginLogId: id })
      }
      this.$store.dispatch('user/fedLogOut')
      location.reload()
    }
  }
}
</script>
<style lang="scss">
.header {
  background: #f2f3f5;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px 0px 8px;
  .el-image__placeholder {
    background: inherit;
  }
  &-left {
    display: flex;
    align-items: center;
    &__logo {
      height: 72px;
      width: 72px;
      &--zl{
        height: 160px;
        width: 160px;
        margin-left: -20px;
      }
    }
    &__title {
      margin-left: -10px;
      z-index: 2;
      height: 28px;
    }
    &__titleZh {
      font-family: PingFangSC-Semibold;
      font-size: 18px;
      color: #1f2329;
      letter-spacing: 0;
      font-weight: 600;
      line-height: 19px;
    }
    &__titleEn {
      font-family: Arial-ItalicMT;
      font-size: 7px;
      color: #1f2329;
      letter-spacing: 0;
      zoom: 0.5;
      line-height: 18px;
      font-weight: 100;
      font-style: oblique;
    }
  }
  &-right {
    display: flex;
    align-items: center;
    padding-right: 24px;
    height: 64px;
    &__explain {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 30px;
      width: 104px;
      border-radius: 4px;
      font-size: 16px;
      color: #1f2329;
      letter-spacing: 1px;
      font-weight: 400;
      justify-content: center;
      &:hover {
        background: #e3e5e7;
      }
      i {
        font-size: 20px;
        margin-right: 3px;
        color: #646a73;
      }
      &-info {
        width: 300px;
        padding: 12px;
        &__title {
          color: #1f2329;
          letter-spacing: 1px;
          line-height: 24px;
          font-weight: 500;
        }
        &__content {
          color: #1f2329;
          letter-spacing: 1px;
          line-height: 24px;
          font-weight: 400;
          padding: 8px 0px;
        }
        &__item {
          font-size: 16px;
          color: #1f2329;
          letter-spacing: 1px;
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 5px;
          a {
            color: #3370ff;
            letter-spacing: 1px;
            line-height: 20px;
            font-weight: 400;
          }
        }
      }
    }
    &__organization {
      max-width: 300px;
      height: 30px;
      display: flex;
      align-items: center;
      margin-right: 16px;
      font-size: 16px;
      color: #1f2329;
      letter-spacing: 1px;
      font-weight: 400;
      line-height: 64px;
      i {
        font-size: 20px;
        color: #646a73;
      }
    }
    &__userInfo {
      display: flex;
      align-items: center;
      padding: 0px 8px;
      cursor: pointer;
      height: 30px;
      margin-left: 16px;
      min-width: 69px;
      justify-content: center;
      border-radius: 4px;
      &:hover {
        background: #e3e5e7;
        border-radius: 4px;
      }
      &-realName {
        font-size: 16px;
        color: #1f2329;
        letter-spacing: 1px;
        line-height: 22px;
        font-weight: 400;
      }
      i {
        font-size: 20px;
        color: #646a73;
      }
      &-popover {
        min-width: 214px;
        &__avatar {
          text-align: center;
          margin-top: 16px;
          i {
            background: #f6faff;
            font-size: 30px;
            padding: 7px 7px;
            color: #3888ff;
            border-radius: 50%;
            border: 1px solid #3888ff;
            font-weight: normal;
          }
        }
        &__realName {
          text-align: center;
          font-size: 16px;
          color: #000000;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          margin-top: 13px;
          margin-bottom: 37px;
        }
        &__line {
          background: rgba(31, 35, 41, 0.15);
          height: 1px;
          opacity: 0.5;
          position: absolute;
          top: 121px;
          width: 100%;
          left: 0px;
        }
        &__item {
          display: flex;
          justify-content: space-between;
          margin-top: 21px;
          margin-bottom: 8px;
          padding: 0px 4px 0px 4px;
          color: #999999;
          font-weight: 400;
          &-title{
            width: 60px;
          }
          &-content {
            color: #000;
          }
        }
      }
    }
    &__logout-btn {
      display: flex;
      align-items: center;
      padding: 0px 8px;
      cursor: pointer;
      height: 30px;
      margin-left: 16px;
      justify-content: center;
      border-radius: 4px;
      &:hover {
        background: #e3e5e7;
        border-radius: 4px;
      }
      i {
        font-size: 20px;
        color: #646a73;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .header-right__explain,
    .header-left__divider,
    .header-left__logo {
      display: none;
    }
  }
}
</style>
